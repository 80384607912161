import { DdpEnvironment } from "./env-interface";

export const environment: DdpEnvironment = {
  production: true,
  okta: {
    clientId: '0oa109r59oisJYOEM0x8',
    issuer: 'https://login.alliancewebs.net/oauth2/ausmkot3jmgtQXrtZ0x7',
  },
  serverUrl: 'https://datadeliveryplatform-api.vizientinc.com',
  versionNumber:'1.2.293-Develop',
  termsUrl:'http://vizientinc.com',
  brandUrl:'http://vizientinc.com',
  complianceUrl: 'https://compliance.vizientinc.com',
};
