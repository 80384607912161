import { Injectable } from '@angular/core';
import { Domain } from 'domain';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { DataSetInfo, DomainData } from '../models';

@Injectable({
  providedIn: 'root'
})

export class DataShareService {

  private userName = new BehaviorSubject<string>('');
  private login = new BehaviorSubject<boolean>(true);
  private isSessionExpired=new BehaviorSubject<boolean>(false);

  private subDomainCollectionData = new BehaviorSubject<DomainData>({
    domainName: '',
    domainDescription: '',
    subDomainCollection: [],
    datasetCollection: [],
    viewStyle: of('grid'),
  });

  private domainData = new BehaviorSubject<DomainData>({
    domainName: '',
    domainDescription: '',
    subDomainCollection: [],
    datasetCollection: [],
    viewStyle: of('grid'),
  });

  private dataSetInfo = new BehaviorSubject<DataSetInfo>({
    domainName: '',
    domainDescription: '',
    ownerEmail: '',
    viewStyle: of('grid'),
  });

  public userName$ = this.userName.asObservable();
  public login$ = this.login.asObservable();
  public isSessionExpired$=this.isSessionExpired.asObservable();
  public subDomainCollectionData$ = this.subDomainCollectionData.asObservable();
  public domainData$ = this.domainData.asObservable();
  public dataSetInfo$ = this.dataSetInfo.asObservable();
  public onFormReset = new Subject<void>();

  constructor() { }

  updateUserName(value: any) {
    this.userName.next(value);
  }

  setSubDomainCollection(value: DomainData) {
    this.subDomainCollectionData.next(value);
  }

  setDomainData(value: DomainData) {
    this.domainData.next(value);
  }

  setDataSetInfo(value: DataSetInfo){
    this.dataSetInfo.next(value);
  }

  clearSessionAndCookie() {
    sessionStorage.clear();
  }

  setLogin(isLogin: boolean) {
    this.login.next(isLogin);
    sessionStorage.setItem("isLogin", isLogin.toString());
  }

  setSessionExpired(isExpired: boolean) {
    this.isSessionExpired.next(isExpired);
  }

  resetForm(): void {
    this.onFormReset.next();
  }

}
