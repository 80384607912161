import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class EnvironmentUrlService {
    public serverUrl: string = environment.serverUrl;
    public oktaIssuer: string = environment.issuer;
    public oktaClientId: string = environment.clientId;
    public oktaRedirectUri: string = environment.redirectUri;
    public complianceUrl: string = environment.complianceUrl;

    constructor() {

    }
}
