import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ComplianceNoticeService {
  private _notificationsAccepted: boolean;

  constructor() {}

  get notificationsAccepted(): boolean {
    return this._notificationsAccepted;
  }

  set notificationsAccepted(value: boolean) {
    if (typeof value === 'boolean') {
      this._notificationsAccepted = value;
    }
  }
}
