<div *ngIf="viewStyle === 'grid'; else listEnabledView">
  <a data-test="list-icon" (click)="listView()">
    <mat-icon
      fontSet="fa"
      fontIcon="fa-list"
      ngClass="icon-inactive"
    ></mat-icon>
  </a>

  <a data-test="grid-icon" (click)="gridView()">
    <mat-icon
      fontSet="fa"
      fontIcon="fa-th-large"
      ngClass="icon-active"
    ></mat-icon>
  </a>
</div>

<ng-template #listEnabledView>
  <a (click)="listView()">
    <mat-icon fontSet="fa" fontIcon="fa-list" ngClass="icon-active"></mat-icon>
  </a>

  <a (click)="gridView()">
    <mat-icon
      fontSet="fa"
      fontIcon="fa-th-large"
      ngClass="icon-inactive"
    ></mat-icon>
  </a>
</ng-template>
