import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-collection-description',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      [ngClass]="lines === 4 ? 'max-lines-4' : 'max-lines-2'"
      class="viz-body-1"
    >
      {{ text }}
    </div>
  `,
  styles: [
    `
      /*
        This chunk allows for the ellipsis on the second line
        unsupported browsers fall back to nowrap white-space
        forcing a single line of text with ellipsi
       */
      .max-lines-2 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 2) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .max-lines-4 {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 4) {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: initial;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainCollectionDescriptionComponent {
  @Input() lines!: 4 | 2;
  @Input() text!: string;
  @Input() matTooltip!: string;
}
