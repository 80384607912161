import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { DomainCollection } from 'src/app/models/index';
import { DomainCollectionViewType } from './../domain-collections-view-type';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-unauthorized-banner',
  standalone: true,
  imports: [CommonModule],
  template: `
    <!-- <div *ngIf="name != '0'" class="access-denied-text">
      <div *ngIf="viewType == 'tile'">
        Please contact your system administrator<br />
        to gain permission to subscribe.<br />
        System administrators,<br />
        please contact your Vizient representative<br />
        to gain access to this collection.
      </div>

      <div *ngIf="viewType == 'list'">
        Please contact your system administrator to gain permission to
        subscribe.<br />
        System administrators, please contact your Vizient representative to
        gain access to this collection.
      </div>
    </div> -->
    <div class="access-denied-text">Coming Soon</div>
  `,
  styles: [
    `
      .access-denied-text {
        text-align: center;
        font-weight: bold;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px;
        background-color: #e9e9e9;
        border-radius: 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedBannerComponent {
  @Input() domainCollection!: DomainCollection;
  @Input() viewType!: DomainCollectionViewType;

  get name() {

      return 'DomainName'; 
  }
}
