import { Domain } from "src/app/models";
import { CardTitleComponent } from "../card-title/card-title.component";
import { DomainCollectionDescriptionComponent } from "../domain-collection-description/domain-collection-description.component";
import { CommonModule } from "@angular/common";
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { UnauthorizedBannerComponent } from "../unauthorized-banner/unauthorized-banner.component";
import { SharedModule } from "src/app/shared";

@Component({
    selector: "app-collection-card",
    standalone: true,
    template: `
    <mat-card *ngIf="domain">
      <card-title
        [domain]="domain"
        (click)="
          openSubDomainView.emit(domain)
        "
      ></card-title>

      <app-collection-description
        [lines]="4"
        [text]="domain.description"
        [matTooltip]="domain.description"
      ></app-collection-description>

      <app-unauthorized-banner *ngIf="domain.isComingSoon">

      </app-unauthorized-banner>

      <!-- adds a section to flexbox so more space in-between these two components -->
      <span *ngIf="!domain.isComingSoon" class="viz-body-1 learn-more" >
      
      </span>
        <a role="button" (click) = "openSubDomainView.emit(domain)" class="viz-body-1 learn-more" target="domain" > Learn more </a>
    </mat-card>
  `,
    styleUrls: ["./collection-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CardTitleComponent,
        DomainCollectionDescriptionComponent,
        CommonModule,
        UnauthorizedBannerComponent,
        SharedModule
    ]
})
export class CollectionCardComponent implements OnInit {
  @Input() domain!: Domain;
  @Output() openSubDomainView = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
  }
}
