<div class="page-container">
  <app-header></app-header>

  <viz-container>
    <viz-main-content>
      <router-outlet></router-outlet>
    </viz-main-content>
  </viz-container>

  <app-footer 
    [contactLink]="email"
    contactDisplay="Contact us"
    [appTitle]="title"
    copyright="Vizient, Inc. All rights reserved."
    legal="These documents, reports, and/or analysis, and information contained therein, are intended for the sole use of Vizient members, customer, and program participants, as described in and in accordance with contract(s) between Vizient and such member, customer, or program participant. The contents of these documents are protected by copyright law, and, except as expressly permitted by Vizient in the relevant agreement between Vizient and the member, customer, or program participant, no part of the documents, reports, analyses or information contained therein may be disclosed, transmitted, disseminated, published, posted, modified, uploaded, circulated, sold, redistributed, copied, or reproduced by any means or by anyone without the prior written permission of Vizient. Please contact legal@vizientinc.com to request permission for reuse."
  ></app-footer>
</div>
