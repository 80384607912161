import { Injectable } from '@angular/core';
import { retry } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.services';
import { HttpClient } from '@angular/common/http';
import { Domain } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DomainsService {
  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {
  }

  getDomainDetail(id: number) {
    const url = `${this.envUrl.serverUrl}/v1/domains/${id}`;
    return this.http.get<Domain>(url).pipe(retry(1));
  }

  getDomainCollectionApi() {
    const url = `${this.envUrl.serverUrl}/v1/domains`;
    return this.http.get<Domain[]>(url).pipe(retry(1));
  }

  getSubDomainCollectionApi(domainId: number) {
    const url = `${this.envUrl.serverUrl}/v1/domains/${domainId}/subdomains`;
    return this.http.get<Domain[]>(url).pipe(retry(1));
  }

  getDomainDatasets(domainId: number) {
    const url = `${this.envUrl.serverUrl}/v1/domains/${domainId}/datasets`;
    return this.http.get<Domain[]>(url).pipe(retry(1));
  }
}

export interface IDomainDto {
  DomainId: number;
  ParentDomainId: number;
  Name: string;
  DisplayName: string;
  DisplayDescription: string;
  DisplayUrl: string;
  DisplayUrlName: string;
}


