import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
    <footer class="footer">
      <div class="footer_contact" data-contact-link>
        <a [href]="contactLink">{{ contactDisplay }}</a>
      </div>

      <div class="footer_copyright">
        <span *ngIf="appTitle" data-app-title>{{ appTitle }}</span>
        <br />

        <span *ngIf="copyright" data-copyright>&copy; {{ year }} {{ copyright }}</span>
        <br />
        <br />

        <span *ngIf="legal" data-legal>{{ legal }}</span>
      </div>

      <div class="footer_terms" data-terms-link>
        <a [href]="termsLink" target="_blank">{{ termsDisplay }}</a>
      </div>
    </footer>
  `,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() contactLink;
  @Input() contactDisplay;

  @Input() appTitle;
  @Input() copyright;
  @Input() legal;

  @Input() termsLink;
  @Input() termsDisplay;

  year = new Date().getFullYear();

  constructor() {}
}
