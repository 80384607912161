<ng-container *ngIf="(subDomainView$ | async) === false; else subDomainView">
  <div data-test="title" class="viz-h3 justify-space-between">
    {{ welcomeText$ | async }}
  </div>

  <br />

  <div data-test="sub-title" class="viz-body-1">
    {{ welcomeDescription$ | async }}
  </div>

  <br />

  <div id="layout-actions-row" class="justify-space-between">
    <app-list-or-card-toggle [viewStyle]="(viewStyle$ | async)!"
      (changeViewStyle)="changeViewStyle($event)"></app-list-or-card-toggle>

    <app-expand-or-compress-toggle *ngIf="(viewStyle$ | async)! === 'list'"
      (expandOrContract)="expandOrContract($event)"></app-expand-or-compress-toggle>
  </div>

  <br />

  <div *ngIf="(viewStyle$ | async)! === 'grid'; else listView" class="api-collections-wrapper flex-wrap"
    style="display: flex">
    <div *ngFor="
        let domaincollection of domainCollections$ | async | filterDomains
      " style="margin: 10px">
      <app-collection-card [domain]="domaincollection"
        (openSubDomainView)="openSubDomainView($event)"></app-collection-card>
      <!--/div-->
    </div>
  </div>

  <ng-template #listView>
    <mat-accordion multi>
      <div *ngFor="
          let domaincollection of domainCollections$ | async | filterDomains
        " style="margin: 10px">
        <app-collection-panel (openSubDomainView)="openSubDomainView($event)" [domain]="domaincollection">
        </app-collection-panel>
      </div>
    </mat-accordion>
  </ng-template>
</ng-container>

<ng-template #subDomainView>
  <router-outlet (deactivate)="onDeactivateRouterOutlet()"></router-outlet>
</ng-template>