<viz-header title={{title}} [brandLink]="brandLink">

  <div *ngIf="(isAuthenticated$ | async) === false; else authenticated">
    <mat-tab-group (selectedTabChange)="redirect($event)">
      <mat-tab label="Domains"></mat-tab>
      <mat-tab label="Login"> </mat-tab>
    </mat-tab-group>
  </div>
  <ng-template #authenticated>
    <viz-nav-menu icon="help" id="iconHelp">
      <viz-nav-menu-item link="https://learning.vizientinc.com/Pages/default.aspx" displayText="Learning Center" id="learningCenter"></viz-nav-menu-item>
      <viz-nav-menu-item link="https://learning.vizientinc.com/Data_Delivery_Platform/Documents/DDP_User_Guide.pdf" displayText="User Guide" id="userGuide"></viz-nav-menu-item>
    </viz-nav-menu>    
    <viz-nav-menu icon="person" id="iconPerson">
      <viz-nav-menu-item routerLink="/" [userName]="(userName$ | async)" id="menuPersonLoggedIn"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/dashboard" displayText="Dashboard" id="menuPersonDashboard"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/schedules" displayText="Schedules" id="menuPersonSchedules"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/my-notifications" displayText="Notifications" id="menuPersonNotifications"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="app/member-storage-accounts" displayText="Storage Accounts" id="menuPersonMemberStorageAccounts"></viz-nav-menu-item>
      <viz-nav-menu-item routerLink="/" (click)="logout()" displayText="Log out" id="menuPersonLogout"></viz-nav-menu-item>
    </viz-nav-menu>
  </ng-template>
</viz-header>
