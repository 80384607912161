import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HttpParamsBuilder {

  constructor(
  )
  {
  }

  build(queryParamsObject: Record<string, string | number | boolean | undefined>) {
    const queryParamsObjectWithValues = {};

    //removes undefined values
    Object.entries(queryParamsObject).forEach(qpo => {
      if(qpo[1]){
        queryParamsObjectWithValues[qpo[0]] = qpo[1];
      }
    });

    return new HttpParams(
      {
        fromObject: queryParamsObjectWithValues
      }
    );
  }
}
