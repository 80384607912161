export const groupBy = <T, K extends keyof any>(array: T[], keyGetter: (i: T) => K) => {
  const map = new Map<K, T[]>();

  array.forEach(arrayItem => {
      const key = keyGetter(arrayItem);
      const values = map.get(key);
      if (!values)
        map.set(key, [arrayItem]);
      else
        values.push(arrayItem);
  });

  return map;
};
