import { Component, OnInit, Optional, Injector, Inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CLIENT } from 'src/app/okta-sso/okta-client';


@Component({
  template: `<div>{{ error }}</div>`,
})
export class AuthCallbackComponent implements OnInit {
  error?: string;

  constructor(
    // @Inject(OKTA_CONFIG) private config: OktaConfig,
    @Inject(OKTA_CLIENT) private readonly oktaAuth: OktaAuth,
    @Optional() private injector?: Injector
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      // Parse code or tokens from the URL, store tokens in the TokenManager, and redirect back to the originalUri
      await this.oktaAuth.handleLoginRedirect();
    } catch (e) {
      console.log(e);
    }
  }
}
