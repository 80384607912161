import { NgModule } from '@angular/core';
import {
  VizBreadcrumbModule,
  VizContainerModule,
  VizDrawerModule,
  VizFeedbackIndicatorModule,
  VizFileUploadModule,
  VizFooterModule,
  VizHeaderModule,
  VizHttpStatusModule,
  VizNodataModule
} from 'vizient-toolkit';
import { CommonModule } from '@angular/common';

const vizList = [
  VizBreadcrumbModule,
  VizContainerModule,
  VizDrawerModule,
  VizFeedbackIndicatorModule,
  VizFileUploadModule,
  VizFooterModule,
  VizHeaderModule,
  VizHttpStatusModule,
  VizNodataModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...vizList
  ],
  exports: [
    ...vizList
  ]
})
export class AppToolkitModule {
}
