import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OktaAuth } from '@okta/okta-auth-js';

import { OKTA_CLIENT } from './okta-client';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class VizAuthGuard implements CanLoad , CanActivate  {
  constructor(
    private readonly authService: AuthService,
    @Inject(OKTA_CLIENT) private readonly oktaAuth: OktaAuth
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
    return this.authService.authenticated$.pipe(
      map((authenticated) => {
        if (authenticated) return true;

        const whole: Array<any> = [''];
        segments.forEach((segment) => {
          whole.push(segment.path);
        });
        const parsedWhole = whole.join('/');
        this.oktaAuth.setOriginalUri(parsedWhole);
        this.oktaAuth.signInWithRedirect();
        return false;
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
      return this.authService.authenticated$.pipe(
        map((authenticated) => {
          if (authenticated) return true;

          this.oktaAuth.setOriginalUri('/');
          this.oktaAuth.signInWithRedirect();
          return false;
        })
      );
  }
}
