import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import staticData from '../../assets/data/data.json';
import { AuthService } from '../okta-sso/auth.service';
import { DataShareService } from '../services';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
  private hardData = staticData.titleValues;

  isAuthenticated$: Observable<boolean>;
  userName$: Observable<string>;
  title: string;
  brandLink= '/';

  constructor(
    private readonly dataShareService: DataShareService,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    this.title = this.hardData[0].headerTitle;
  }

  ngOnInit() {
    this.isAuthenticated$ = this.authService.authenticated$;
    this.userName$ = this.authService.userName$;

    this.dataShareService.isSessionExpired$.subscribe(isExpired => {
      if(isExpired)
        this.authService.signOut();
   });

  }

  async redirect(event){
    if(event.index === 0){
      window.location.href = '/';
    }
    else if(event.index === 1){
      await this.authService.signInWithRedirect({ originalUri: this.router.url });
    };
  }

  logout(){
    this.authService.signOut();
    this.dataShareService.updateUserName('');
    this.dataShareService.clearSessionAndCookie();
    window.location.href = '/';
  }
}
