import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, filter } from 'rxjs';

import { environment } from 'src/environments/environment';
import { DataShareService } from './services';
import { AuthService } from './okta-sso/auth.service';
import staticData from '../assets/data/data.json';

// Needed for dynatrace
declare let dT_: {
  initAngularNg: (arg0: HttpClient, arg1: typeof HttpHeaders) => void;
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
  private hardData = staticData.titleValues;
  sideHeader = this.hardData[0].headerTitle;
  title = `${this.hardData[0].headerTitle} ver: `;
  versionNumber = environment.versionNumber;
  email = "mailto:membersupport-apis@vizientinc.com";
  termLink = environment.termsUrl;
  userName$: Observable<string>;

  constructor(private datashareService: DataShareService, private router: Router, private authService: AuthService, http: HttpClient) {
    // Dynatrace snippet to send
    const headElement = document.getElementsByTagName('head')[0];
    // only run this code when config.production
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(http, HttpHeaders);
    }
    this.placeDtTag(headElement);

    this.title = this.title + this.versionNumber;
    datashareService.setLogin(false);
  }

  ngOnInit() {
    this.userName$ = this.authService.userName$;
    this.datashareService.isSessionExpired$.subscribe(isExpired => {
      if (isExpired == true) {
        // logout the user if the user session is expired
        this.authService.signOut();
      }
    });
    this.router.events
    // For newer versions or rxjs use a pipe on the filter:
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(() => {
      document.querySelector('.viz-main-content').scrollTop = 0;
    });
  }

  private placeDtTag(headElement: HTMLElement) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = `assets/dynatrace.min.js`;

    headElement.insertBefore(script, headElement.firstChild);
  }
}
