export const mapEnumToKeyValueArray = (enumType: { [key: number]: string }): { key: string; value: string }[] => {
    const keyValueArray: { key: string; value: string }[] = [];
    for (const key in enumType) {
        if (isNaN(Number(enumType[key]))) {
            keyValueArray.push({ key, value: String(enumType[key]) });
        }
    }

    return keyValueArray;
};
