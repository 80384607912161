import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../okta-sso/auth.service';


@Injectable({ providedIn: "root" })
export class VizAuthInterceptorService implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.authState$.pipe(
      take(1),
      switchMap((vizAuthState) => {
        if (!vizAuthState.accessToken) return next.handle(req);
        const modifiedReqWithAuthHeader = req.clone({
          headers: req.headers.set(
            "Authorization",
            `Bearer ${vizAuthState.accessToken}`
          ),
        });
        return next.handle(modifiedReqWithAuthHeader).pipe(
          catchError((err) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.authService.signOut();
                return new Observable<HttpEvent<any>>();
              }
            }
          })
        );
      })
    );
  }
}
