/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { map, Observable, retry, Subject } from 'rxjs';
import { EnvironmentUrlService } from './environment-url.services';
import { HttpClient } from '@angular/common/http';
import { DatasetField, DatasetFieldMultiValues } from '../models';
import { HttpParamsBuilder } from '../utilities';


import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatasetFieldService {
  private datasetFieldMultiValuesChangedSource: Subject<{ [subDatasetId: number]: { [datasetFieldId: number]: DatasetFieldMultiValues[] } }>;
  datasetFieldMultiValuesChanged$: Observable<{ [subDatasetId: number]: { [datasetFieldId: number]: DatasetFieldMultiValues[] } }>;

  private commonDatasetFieldChangedSource: Subject<{ dbFieldName: string; selectedFilterCodeId: string; userValue: any }>;
  commonDatasetFieldChanged$: Observable<{ dbFieldName: string; selectedFilterCodeId: string; userValue: any}>;

  constructor(
    private readonly envUrl: EnvironmentUrlService,
    private readonly http: HttpClient,
    private readonly httpParamsBuilder: HttpParamsBuilder
  ) {
    this.datasetFieldMultiValuesChangedSource = new Subject();
    this.datasetFieldMultiValuesChanged$ = this.datasetFieldMultiValuesChangedSource.asObservable();

    this.commonDatasetFieldChangedSource = new Subject();
    this.commonDatasetFieldChanged$ = this.commonDatasetFieldChangedSource.asObservable();
  }
  // Private BehaviorSubject
  private mandatoryFiltersSubject: BehaviorSubject<Map<string, boolean>> = new BehaviorSubject<Map<string, boolean>>(new Map<string, boolean>());

  // Observable derived from the BehaviorSubject
  public mandatoryFiltersObservable: Observable<Map<string, boolean>> = this.mandatoryFiltersSubject.asObservable();

  // Getter for mandatoryFilters (returns the current value of the BehaviorSubject)
  get mandatoryFilters(): Map<string, boolean> {
    return this.mandatoryFiltersSubject.getValue();
  }

  // Setter for mandatoryFilters (updates the BehaviorSubject with a new value)
  set mandatoryFilters(filters: Map<string, boolean>) {
    this.mandatoryFiltersSubject.next(filters);
  }

  emitDatasetFieldMultiValuesChanged(datasetFieldMultiValuesByDatasetFieldIdAndBySubDatasetId: { [subDatasetId: number]: { [datasetFieldId: number]: DatasetFieldMultiValues[] } }) {
    this.datasetFieldMultiValuesChangedSource.next(datasetFieldMultiValuesByDatasetFieldIdAndBySubDatasetId);
  }

  emitCommonDatasetFieldChanged(commonDatasetField: { dbFieldName: string; selectedFilterCodeId: string; userValue: any }) {
    this.commonDatasetFieldChangedSource.next(commonDatasetField);
  }

  /**
   * Retrieves a list of dataset fields associated with a specific dataset.
   *
   * @param subDatasetId - The unique identifier of the dataset to fetch fields for.
   * @param basicMode - flag for only basic props.
   * @param memberId - ID of Member.
   * @returns An Observable that emits an array of dataset fields.
   */
  get(subDatasetId?: number, basicMode?: boolean, memberId?: number) {
    return this.http.get<DatasetField[]>(
      `${this.envUrl.serverUrl}/v1/datasetfields`,
      {
        params: this.httpParamsBuilder.build({
          subDatasetId,
          basicMode,
          memberId,
        }),
      }
    ).pipe(retry(1));
  }

  getMultiValues(datasetFieldIds: string, memberId: number) {
    return this.http.get<{ [datasetFieldId: number]: DatasetFieldMultiValues[] }>(
      `${this.envUrl.serverUrl}/v1/datasetfields/multi-values`,
      {
        params: this.httpParamsBuilder.build({
          datasetFieldIds,
          memberId,
        }),
      }
    ).pipe(retry(1));
  }

  checkIfAllMandatoryFieldsAreTrue$(): Observable<boolean> {
    return this.mandatoryFiltersSubject.asObservable().pipe(
      map((filters: Map<string, boolean>) => {
        // Check if all values in the Map are true
        for (const value of filters.values()) {
          if (!value) {
            return false;
          }
        }
        return true;
      })
    );
  }

  emptyMandatoryFilters(): void {
    const emptyMap = new Map<string, boolean>(); // Create an empty Map
    this.mandatoryFiltersSubject.next(emptyMap); // Emit the empty Map
  }

  updateMandatoryFilter(key: string, value: boolean) {
    const currentFilters = this.mandatoryFilters;
    currentFilters.set(key, value); // Update the specific filter
    this.mandatoryFilters = currentFilters; // Trigger the setter to update the BehaviorSubject
  }
}
