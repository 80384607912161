import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { SharedModule } from 'src/app/shared';

@Component({
  selector: 'app-expand-or-compress-toggle',
  standalone: true,
  imports: [SharedModule],
  template: `
    <a (click)="expandOrContract.emit('expand')">
      <mat-icon
        fontSet="fa"
        fontIcon="fa-expand"
        ngClass="icon-active"
      ></mat-icon>
    </a>

    <a (click)="expandOrContract.emit('compress')">
      <mat-icon
        fontSet="fa"
        fontIcon="fa-compress"
        ngClass="icon-active"
      ></mat-icon>
    </a>
  `,
  styleUrls: ['./expand-or-compress-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandOrCompressToggleComponent {
  @Output() expandOrContract = new EventEmitter<'expand' | 'compress'>();

  constructor() {}
}
