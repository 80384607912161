import { NgModule } from "@angular/core";
import { CommonModule, NgFor, NgIf } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppMaterialModule } from "./app-material.module";
import { AppToolkitModule } from "./app-toolkit.module";

@NgModule({
  imports: [CommonModule], // todo; not certain this needs to be imported as well
  exports: [
    AppMaterialModule,
    AppToolkitModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    NgFor,
  ],
})
export class SharedModule {}
