import { Injectable } from "@angular/core";
import { retry, catchError } from "rxjs";
import { EnvironmentUrlService } from "./environment-url.services";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SubProductsDto, UserDto, UserProductRole, UserProductRoleMember } from "../models";
import { ResponseStatus } from "../enums";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private envUrl: EnvironmentUrlService,
    private http: HttpClient
  ) {}

  getUser(userEmail: string) {
    const url = `${this.envUrl.serverUrl}/v1/dbusers`;

    return this.http.get<UserDto>(url).pipe(
      retry(1),
      catchError((error: any) => {
        if (error.status === ResponseStatus.Unauthorized) {
          console.log("Unauthenticated request");
        }
        throw error;
      })
    );
  }

  getUserRoles(email: string) {
    const url = `${this.envUrl.serverUrl}/v1/users/products/roles`;
    return this.http.get<UserProductRoleMember[]>(url).pipe(retry(1));
  }

  addUpdateUser(email: string) {
    const url = `${this.envUrl.serverUrl}/v1/users/addOrUpdateUser`;
    return this.http.get<UserDto>(url).pipe(retry(1));
  }

  getSubProducts() {
    const url = `${this.envUrl.serverUrl}/v1/iam/subproducts`;
    return this.http.get<SubProductsDto>(url).pipe(retry(1));
  }

  getLoggedUserProductClients(productId: number) {
    const url = `${this.envUrl.serverUrl}/v1/logged-user/products/${productId}/clients`;
    return this.http.get<any[]>(url).pipe(retry(1));
  }

  getLoggedUserProductRoles(productId: number, roleId: number){
    const url = `${this.envUrl.serverUrl}/v1/logged-user/product-roles?productId=${productId}&roleId=${roleId}`;
    return this.http.get<UserProductRole[]>(url).pipe(retry(1));
  }
}
