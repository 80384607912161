import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'card-title',
  standalone: true,
  template: `
    <i data-test="domaincollection-icon" [ngClass]="domain['datasetId'] ? 'fa fa-table icon' : 'fa fa-cubes icon'"></i>
    <div data-test="domaincollection-title" class="domaincollection-title viz-h6">
      {{ domain.name }}
    </div>
  `,
  styleUrls: ['./card-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
      CommonModule,
  ]
})
export class CardTitleComponent implements OnInit {
  @Input() domain!: any;
  constructor() {}

  ngOnInit(): void {}
}
