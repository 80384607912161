import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SharedModule } from 'src/app/shared';

type ViewStyle = 'list' | 'grid';

@Component({
  selector: 'app-list-or-card-toggle',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './list-or-card-toggle.component.html',
  styleUrls: ['./list-or-card-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListOrCardToggleComponent {
  @Output() changeViewStyle = new EventEmitter<ViewStyle>();
  private _viewStyle: ViewStyle = 'grid';
  constructor() {}

  @Input()
  get viewStyle(): ViewStyle {
    return this._viewStyle;
  }
  set viewStyle(viewStyle: ViewStyle) {
    this._viewStyle = viewStyle;
  }

  listView() {
    this.changeViewStyle.emit('list');
  }

  gridView() {
    this.changeViewStyle.emit('grid');
  }
}
